// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

// Header Skins
.kt-header-base-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
	@import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
	@import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
	@import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

// Primefaces themes
@import "../node_modules/primereact/resources/primereact.min.css";
@import "../node_modules/primereact/resources/themes/nova-light/theme.css";
@import "../node_modules/primeicons/primeicons.css";

// Fullcalendar
@import "../node_modules/@fullcalendar/core/main.css";
@import "../node_modules/@fullcalendar/daygrid/main.css";
@import "../node_modules/@fullcalendar/timegrid/main.css";
@import "../node_modules/@fullcalendar/list/main.css";

@-webkit-keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@keyframes fadeInUpContent {
	from {
		opacity: 0;
		-webkit-transform: translate3d(0, -5%, 0);
		transform: translate3d(0, -5%, 0);
	}

	to {
		opacity: 1;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

.fadeInUpContent {
	-webkit-animation-name: fadeInUpContent;
	animation-name: fadeInUpContent;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__link
	.kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item.kt-menu__item--open
	> .kt-menu__heading
	.kt-menu__link-icon,
.kt-aside-dark .kt-aside-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--open > .kt-menu__link .kt-menu__link-icon {
	color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item:not(.kt-menu__item--parent):not(.kt-menu__item--open):not(.kt-menu__item--here):not(
		.kt-menu__item--active
	):hover
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__heading
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span,
.kt-aside-dark
	.kt-aside-menu
	.kt-menu__nav
	> .kt-menu__item
	.kt-menu__submenu
	.kt-menu__item.kt-menu__item--active
	> .kt-menu__link
	.kt-menu__link-bullet.kt-menu__link-bullet--dot
	> span {
	background-color: #607d8b;
}

.btn-primary {
	background-color: #147dbf !important;
	border-color: #147dbf !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	background-color: #36a5eb !important;
	border-color: #36a5eb !important;
	color: #fff !important;
}

.kt-login__title {
	color: #424242 !important;
	font-weight: 600 !important;
}

.kt-login__subtitle {
	font-size: 1.3rem !important;
	color: #616161 !important;
}

.kt-login__copyright {
	color: #424242 !important;
}

.kt-login__menu > a {
	text-decoration: none !important;
	color: #424242 !important;
	text-shadow: 1px 1px 5px #fff !important;
}

.kt-login__menu > a:hover {
	text-decoration: none !important;
	color: #212121 !important;
}

.btn-primary:disabled {
	background-color: #67666e !important;
	border-color: #67666e !important;
}

.MuiInput-underline:after {
	border-bottom: 2px solid #67666e !important;
}

.MuiFormLabel-root.Mui-focused {
	color: #67666e !important;
}

.margin-icon {
	margin-right: 8px;
}

.btn-table-action > i {
	width: 40px !important;
	font-size: 13px !important;
	padding: 0 !important;
}

.btn-table-action {
	margin: 0px 3px 0px 3px;
	height: 40px;
	width: 40px;
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.btn-table-action-small > i {
	width: 30px !important;
	font-size: 11px !important;
}

.btn-table-action-small {
	height: 30px;
	width: 30px;
}

.p-datatable-emptymessage {
	height: 100px !important;
	text-align: center !important;
	font-size: 21px !important;
	font-weight: 600 !important;
}

.p-paginator-bottom {
	padding: 10px !important;
	background: #fff !important;
	border: 1px solid #f4f4f4 !important;
	border-bottom-right-radius: 8px !important;
	border-bottom-left-radius: 8px !important;
	text-align: right !important;
}

.p-paginator-current {
	color: #434349 !important;
}

.p-paginator-element.p-link {
	margin: 0px 2px 0px 2px !important;
	border-radius: 5px !important;
}

.p-button {
	margin: 0;
	border-radius: 0.25rem !important;
	border: none !important;
}

.p-datatable .p-datatable-thead > tr > th {
	text-align: left !important;
	background-color: #fff !important;
	border: 1px solid #f4f4f4 !important;
}

.p-datatable .p-datatable-thead > tr {
	height: 50px !important;
}

.p-datatable .p-datatable-tbody > tr > td {
	border: 1px solid #f4f4f4 !important;
}

.p-datatable-header {
	background-color: #fff !important;
	border: none !important;
	padding-right: 0 !important;
	padding-left: 0 !important;
}

.p-datatable-loading-overlay.p-component-overlay {
	background-color: #fff !important;
}

.p-datatable-loading-content {
	top: 15% !important;
}

.p-inputtext {
	border: 1px solid #e2e1e1 !important;
}

label.p-dropdown-label.p-inputtext {
	border: none !important;
}

.btn > i.fa-spin {
	padding-right: 0px !important;
	margin-right: 16px !important;
}

.kt-portlet__head-title {
	padding-top: 15px !important;
	padding-bottom: 15px !important;
}

.imagemUploaderBotaoRemover {
	width: 100%;
	border-radius: 0px 0px 10px 10px;
}

.imagemUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
	display: flex;
	justify-content: center;
	align-items: center;
}

.videoUploaderBackground {
	height: 250px;
	width: 100%;
	border-radius: 10px 10px 0px 0px;
	background-size: cover !important;
	display: flex;
	justify-content: center;
	align-items: center;
	object-fit: cover;
}

.limitar-1-linha {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	flex: 1;
}

.imagemUploaderMargem {
	margin-top: 15px;
	margin-bottom: 15px;
}

.fc-day-number {
	color: #424242 !important;
}

.fc-toolbar > * > :not(:first-child) {
	margin-left: 0.4em !important;
}

.fc-button-primary {
	color: #00465d !important;
	background-color: white !important;
	border-color: #00465d !important;
	border-radius: 5px !important;
	height: 40px !important;
	padding-left: 15px !important;
	padding-right: 15px !important;
}

.fc-button-primary:hover {
	color: white !important;
	background-color: #00465d !important;
	border-color: #00465d !important;
}

.fc-button-primary:not(:disabled):active,
.fc-button-primary:not(:disabled).fc-button-active {
	color: #fff !important;
	background-color: #00465d !important;
	border-color: #00465d !important;
}

.fc-button-primary:disabled {
	color: #fff !important;
	background-color: #8d39bf !important;
	border-color: #8d39bf !important;
}

.fc-center > h2 {
	color: #333 !important;
}

.fc .fc-row {
	border-right: none !important;
}

.fc th {
	background-color: #fafafa !important;
	border: 1px solid #ddd !important;
	color: inherit !important;
}

a.fc-event:hover {
	color: white !important;
}

.btn-secondary:hover {
	color: #595d6e !important;
}

.btn-app-color {
	border-color: #00465d !important;
	background-color: white !important;
	color: #00465d !important;
}

.btn-app-color:hover {
	border-color: #00465d !important;
	background-color: #00465d !important;
	color: white !important;
}

.MuiInputBase-input.Mui-disabled {
	color: rgba(0, 0, 0, 0.65) !important;
}

.p-datatable-tbody td {
	word-break: break-all;
	white-space: wrap;
	text-overflow: ellipsis;
}

.add-imagem {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	gap: 5px;
}

.btn-remover-imagem {
	position: absolute;
	width: 25px;
	height: 25px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 99;
}

.btn-remover-imagem i {
	font-size: 15px !important;
}

.imagem-upload {
	max-height: 300px;
	max-width: 400px;
	min-width: 80px;
	width: 100%;
}

.col-btn-with-input {
	display: flex;
	align-items: flex-end;
	padding-bottom: 8px;
}

.btn-with-input {
	height: 52px;
}

.label-with-texfield {
	margin-bottom: 0px !important;
}

.kt-scrolltop {
	background: #147dbf !important;
}

.kt-link:hover {
	color: #147dbf !important;
}

.p-sortable-column-icon {
	color: #848484 !important;
}

.p-column-title {
	color: #333333 !important;
}

/* CUSTOM DETRONIC */
* {
	font-family: "Lato", sans-serif !important;
}

::-webkit-scrollbar {
	width: 6px;
	height: 8px;
	border-radius: 50%;
}

::-webkit-scrollbar-button {
	width: 0px;
	height: 0px;
	border-radius: 50%;
}

::-webkit-scrollbar-thumb {
	background: #808080;
	border: 0px none #ffffff;
	border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
	background: #808080;
}

::-webkit-scrollbar-thumb:active {
	background: #808080;
}

::-webkit-scrollbar-track {
	background: transparent;
	border: 0px none #ffffff;
	border-radius: 6px;
}

::-webkit-scrollbar-track:hover {
	background: transparent;
}

::-webkit-scrollbar-track:active {
	background: transparent;
}

::-webkit-scrollbar-corner {
	background: transparent;
	border-radius: 6px;
}

.header-landing-page {
	display: flex;
	height: 118px;
	padding: 0px 120px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #fff;
	backdrop-filter: blur(50px);
	opacity: 0.8;
}

.header-landing-page img {
	height: 48px;
	width: 210px;
}

.header-landing-page a {
	border-radius: 8px;
	border: 1px solid #1e407d;
	width: 216px;
	height: 56px;
	color: #1e407d;
	font-size: 20px;
	line-height: 30px;
	font-weight: 500;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
}

.footer-landing-page {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	padding: 80px 120px;
}

.img-footer-landing-page {
	width: auto;
	height: 72px;
}

.container-redes-sociais {
	display: flex;
	margin-top: 32px;
	gap: 16px;
	flex-direction: row;
}

.container-rede-social {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 48px;
	height: 48px;
	border-radius: 4px;
	border: 1px solid #e1e4e8;
}

.container-outras-informacoes {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.container-outras-informacoes a {
	color: #5b5f67;
	font-size: 20px;
	font-weight: 400;
	line-height: 30px;
	margin: 0px;
}

.color-error {
	color: #db0d15;
}

.color-green {
	color: #5cb572;
}

.color-gray-100 {
	color: #f3f5f8;
}

.color-gray-200 {
	color: #2f3435;
}

.color-gray-300 {
	color: #d5d8dc;
}

.color-gray-400 {
	color: #a4a9af;
}

.color-gray-500 {
	color: #abb0b8;
}

.color-gray-600 {
	color: #656b71;
}

.color-gray-700 {
	color: #5b5f67;
}

.color-gray-800 {
	color: #373a41;
}

.color-gray-900 {
	color: #121417;
}

.bold {
	font-weight: 700 !important;
}

.semibold {
	font-weight: 600 !important;
}

.medium {
	font-weight: 500 !important;
}

.regular {
	font-weight: 400 !important;
}

.uppercase {
	text-transform: uppercase;
}

.hh-40 {
	font-style: normal;
	font-weight: 400;
	font-size: 40px;
	line-height: 52px;
}

.hh-32 {
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	line-height: 48px;
}

.hh-28 {
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 42px;
}

.hh-24 {
	font-style: normal;
	font-weight: 400;
	font-size: 24px;
	line-height: 32px;
}

.hh-20 {
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 30px;
}

.tt-18 {
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 28px;
}

.tt-16 {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}

.tt-14 {
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
}

.tt-12 {
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

.tt-10 {
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 16px;
}

.header-onboarding {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 40px;
	height: 112px;
}

.header-onboarding img {
	width: auto;
	height: 40px;
}

.header-onboarding p {
	cursor: pointer;
}

.footer-onboarding {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0px 40px;
	height: 88px;
	background-color: #fff;
}

.trash-icon {
	width: 16px;
	height: 16px;
}

.footer-onboarding div {
	display: flex;
	gap: 24px;
	align-items: center;
}

.container-input-custom {
	display: flex;
	flex-direction: column;
	gap: 16px;
	justify-content: flex-start;
}

.container-input-custom small {
	position: absolute;
	top: 16px !important;
	pointer-events: none;
}

.container-input-custom input,
.container-input-custom select {
	border-width: 0px 0px 1px 0px;
	border-color: #cdd1d7;
	padding-bottom: 16px;
	font-size: 24px;
	line-height: 36px;
	color: #121417;
}

.icone-direito {
	position: absolute;
	z-index: 10;
	right: 10px;
	bottom: 45px;
}

.placeholder {
	font-size: 24px;
	line-height: 36px;
	color: #121417;
}

.container-input-custom input::placeholder {
	font-size: 24px;
	line-height: 36px;
	color: #121417;
}

.container-input-custom input:focus,
.container-input-custom select {
	outline: none;
}

.container-import-no-dashed {
	border: 1px solid #cdd1d7;
	border-radius: 8px;
	min-height: 286px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.container-import {
	border: 1px dashed #cdd1d7;
	border-radius: 8px;
	min-height: 286px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.dragging {
	border: 1px dashed #00465d;
}

.container-import label,
.container-import-no-dashed label {
	cursor: pointer;
	text-decoration: underline;
	text-underline-offset: 5px;
}

.margin-top-file-pdf {
	margin-top: 48px;
}

.upload {
	width: 32px;
	height: 32px;
	margin-bottom: 30px;
}

.container-file {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px;
	border: 1px solid #e1e4e8;
	border-radius: 6px;
	margin-top: 48px;
}

.container-texto-file {
	display: flex;
	gap: 24px;
	align-items: center;
}

.container-texto-file div p {
	display: -webkit-box;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

.botao-exclusao {
	background-color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	gap: 8px;
	border-radius: 4px;
	border: 1px solid #e1e4e8;
	height: 40px;
}

.imagem {
	height: 100%;
	width: 100%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.import {
	width: 320px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	height: 100%;
	padding: 16px;
}

.file-icon {
	width: 20px;
	height: 20px;
}

.close-icon {
	width: 20px;
	height: 20px;
	cursor: pointer;
}

.mobile-only {
	display: none;
}

@media screen and (max-width: 1280px) {
	.footer-landing-page,
	.footer-onboarding {
		padding-right: 20px;
		padding-left: 20px;
	}

	.header-onboarding {
		padding: 0px 20px;
	}

	.header-landing-page {
		padding: 20px;
		height: auto;
	}
}

@media screen and (max-width: 768px) {
	.mobile-only {
		display: flex;
	}

	.desktop-only {
		display: none;
	}

	.header-landing-page img {
		height: 40px;
		width: 176px;
	}

	.header-onboarding {
		height: 96px;
	}

	.header-onboarding img {
		height: 32px;
		width: 144px;
	}

	.header-landing-page a {
		height: 48px;
		width: 95px;
		font-size: 16px;
		line-height: 24px;
	}

	.header-landing-page {
		gap: 30px;
		padding-top: 28px;
		padding-bottom: 28px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.footer-landing-page {
		flex-direction: column;
		padding-top: 80px;
		padding-bottom: 80px;
		gap: 40px;
	}
}

@media screen and (max-width: 576px) {
	.img-footer-landing-page {
		width: 216px;
		height: 56px;
	}

	.file-icon,
	.close-icon {
		width: 24px;
		height: 24px;
	}

	.footer-onboarding {
		height: 96px;
		align-items: flex-start;
		padding-top: 24px;
		padding-bottom: 24px;
	}

	.footer-onboarding div {
		flex-direction: column;
		gap: 8px;
		align-items: flex-end;
	}

	.footer-onboarding a,
	.footer-onboarding p {
		font-size: 14px;
		line-height: 20px;
	}
}
